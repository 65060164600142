import Image1 from './../assets/images/stock.png'
import Image2 from './../assets/images/datascience.png'
import Image3 from './../assets/images/image3.jpg'

const data = [
  /*{
    title: 'Financial Mathematics III',
    website: '',
    description: ``,
    location: '../../finmath/',
    image: Image1,
  },*/
  {
    title: 'Stochastic Analysis',
    website: '',
    description: ``,
    location: '../../stochasticanalysis/',
    image: Image1,
  },
  {
    title: 'Pricing Theory',
    website: '',
    description: ``,
    location: '../../pricingtheory/',
    image: Image3,
  }
]

export default data
